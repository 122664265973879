<template>
  <div>
    <interval-date-picker
      :key="`interval-date-picker-${date}-${interval}`"
      :year="false"
      class="px-3"
      @date-updated="date = $event"
      @end-date-updated="endDate = $event"
      @interval-updated="interval = Number($event)"
      @range-updated="fixedAt = $event.endTime"
    >
      <template #append-btn-toggle>
        <p class="ma-0 px-4 py-0 d-flex align-center">
          報
        </p>
      </template>
      <template #append-outer-btn-toggle>
        <v-btn
          color="primary"
          :ripple="false"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="!isDailyReport"
          :class="{ 'mb-4': $vuetify.breakpoint.xsOnly }"
          @click="onClosingDialog = true"
        >
          <v-icon left>
            {{ icons.mdiClockEnd }}
          </v-icon>
          クロージング
        </v-btn>
      </template>
    </interval-date-picker>

    <keep-alive>
      <div>
        <v-card-text
          v-if="interval === 0 && !endDate"
          class="pt-0"
        >
          <v-row class="my-2">
            <v-col cols="4">
              <daily-weather
                :key="`daily-weather-name-${date}-${interval}-${endDate}-${onClosingDialog}`"
                :date="date"
                :readonly="true"
              />
            </v-col>

            <v-col cols="8">
              <daily-club-event
                :key="`daily-club-event-name-${date}-${interval}-${endDate}-${onClosingDialog}`"
                :date="date"
                :readonly="true"
              />
            </v-col>
          </v-row>

          <daily-report-closer
            :key="`daily-report-closer-name-${date}-${interval}-${endDate}-${onClosingDialog}`"
            :date="date"
            :readonly="true"
          />
        </v-card-text>

        <business-result
          :key="`business-result-${date}-${interval}-${endDate}-${closingDialogCount}`"
          :date="date"
          :interval="Number(interval)"
          :end-date="endDate"
          :fixed-at="fixedAt"
        />

        <download-all-data-btn
          :key="`download-all-data-btn-${date}-${interval}-${endDate}-${closingDialogCount}`"
          :date="date"
          :interval="Number(interval)"
          :end-date="endDate"
        />
      </div>
    </keep-alive>

    <v-dialog
      :value="onClosingDialog"
      transition="dialog-bottom-transition"
      fullscreen
      scrollable
      persistent
      content-class="closing-dialog-card"
    >
      <v-card
        tile
        class="h-full"
      >
        <v-app-bar>
          <v-app-bar-title>
            <v-icon left>
              {{ icons.mdiClockEnd }}
            </v-icon>
            {{ date }}
            <small>クロージング</small>
          </v-app-bar-title>
          <v-spacer />
          <v-app-bar-nav-icon>
            <v-btn
              fab
              icon
              large
              :ripple="false"
              @click="onClosingDialog = false; closingDialogCount += 1;"
            >
              <v-icon>
                {{ icons.mdiWindowClose }}
              </v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-card-text class="h-full pt-4 closing-dialog-card">
          <v-card-text>
            <v-row class="my-2">
              <v-col cols="4">
                <daily-weather
                  :key="`daily-weather-${date}-${interval}-${endDate}-${onClosingDialog}`"
                  :date="date"
                />
              </v-col>

              <v-col cols="8">
                <daily-club-event
                  :key="`daily-club-event-${date}-${interval}-${endDate}-${onClosingDialog}`"
                  :date="date"
                />
              </v-col>
            </v-row>

            <daily-report-closer
              :key="`daily-report-closer-${date}-${interval}-${endDate}-${onClosingDialog}`"
              :date="date"
              class="mt-2"
            />
          </v-card-text>

          <earnings
            :key="`closing-earnings-${date}-${interval}-${endDate}-${onClosingDialog}`"
            :date="date"
            :users="users"
            :fixed-at="fixedAt"
            class="mb-6"
          />

          <cash-register
            :key="`cash-register-${date}-${interval}-${endDate}-${onClosingDialog}`"
            :date="date"
            :fixed-at="fixedAt"
          />

          <v-card-actions class="mt-15">
            <v-btn
              x-large
              block
              :ripple="false"
              color="primary"
              @click="onClosingDialog = false; closingDialogCount += 1;"
            >
              完了
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import { mdiClockEnd, mdiWindowClose } from '@mdi/js'
import useQueryString from '@/views/composable/useQueryString'
import BusinessResult from '@/views/components/daily_report/BusinessResult/BusinessResult.vue'
import Earnings from '@/views/components/daily_report/Closing/Earnings.vue'
import CashRegister from '@/views/components/daily_report/Closing/CashRegister.vue'
import DailyReportCloser from '@/views/components/daily_report/Closing/DailyReportCloser.vue'
import DailyWeather from '@/views/components/daily_report/Closing/DailyWeather.vue'
import DailyClubEvent from '@/views/components/daily_report/Closing/DailyClubEvent.vue'
import IntervalDatePicker from '@/views/components/util/filter/IntervalDatePicker.vue'
import DownloadAllDataBtn from '@/views/components/util/xlsx/DownloadAllDataBtn.vue'

export default {
  components: {
    IntervalDatePicker,
    BusinessResult,
    Earnings,
    CashRegister,
    DailyReportCloser,
    DailyWeather,
    DailyClubEvent,
    DownloadAllDataBtn,
  },
  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const { route } = useQueryString()
    const currentClubMeta = inject('currentClubMeta', { today: new Date().toISOString().substr(0, 10) })
    const date = ref(route.value.query.date || currentClubMeta.today)
    const endDate = ref(route.value.query.endDate)
    const interval = ref(Number(route.value.query.interval) || 0)
    const onClosingDialog = ref(false)
    const closingDialogCount = ref(0)
    const fixedAt = ref()

    // NOTE: 週報, 月報, 任意の期間報でもなく日報になっているか
    const isDailyReport = computed(() => {
      return +interval.value === 0 && !endDate.value
    })

    return {
      // data
      date,
      endDate,
      interval,
      onClosingDialog,
      closingDialogCount,
      fixedAt,

      // computed
      isDailyReport,

      icons: {
        mdiClockEnd,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

@include theme--child(v-dialog--active) using ($material) {
  .closing-dialog-card {
    background-color: map-deep-get($material, 'background') !important;
  }
}
</style>
